import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024.000000 1024.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)">


<path d="M4895 8319 c-175 -15 -354 -53 -550 -116 -922 -297 -1615 -1091
-1780 -2038 -18 -102 -45 -346 -45 -399 0 -1 -103 0 -228 2 -126 2 -243 0
-262 -5 -42 -11 -75 -52 -65 -82 3 -12 16 -31 28 -41 20 -19 38 -20 534 -20
l513 0 0 -40 0 -40 -223 0 c-211 0 -224 -1 -245 -20 -27 -26 -28 -70 -3 -101
19 -24 22 -24 248 -29 l228 -5 3 -42 3 -43 -294 0 c-283 0 -295 1 -321 21 -23
18 -26 28 -26 80 0 50 -4 64 -25 84 -31 32 -74 33 -104 2 -20 -23 -21 -32 -19
-157 4 -191 -35 -174 436 -177 l376 -2 13 -43 c7 -24 13 -46 13 -50 0 -5 -243
-8 -539 -8 -581 0 -560 2 -576 -51 -8 -25 11 -75 33 -86 10 -4 142 -10 296
-13 l278 -5 15 -40 c139 -374 327 -665 628 -973 251 -258 454 -408 755 -558
359 -179 723 -264 1130 -264 354 0 640 54 958 181 291 116 520 261 762 482
l105 96 406 1 c350 0 412 2 455 17 105 35 207 131 244 231 19 51 21 75 29 335
1 27 2 27 65 27 108 0 175 35 223 118 35 60 37 136 4 200 -44 85 -106 123
-217 130 -61 4 -74 8 -74 22 0 9 9 25 20 35 38 35 17 96 -41 119 -22 9 -39 20
-39 26 0 5 -14 25 -31 45 l-31 35 110 0 c107 0 111 1 131 26 26 33 27 65 2 95
l-19 24 -501 -3 -501 -4 0 46 0 46 211 0 c141 0 218 4 232 12 50 26 60 69 26
112 l-20 26 -209 0 -208 0 -4 40 -4 40 461 0 462 0 21 23 c28 30 29 78 1 103
-19 17 -39 19 -225 19 l-204 0 -4 40 c-3 22 -8 81 -11 130 -30 456 -219 951
-513 1345 -541 725 -1408 1117 -2297 1039z m288 -356 c20 -37 55 -63 120 -87
26 -10 47 -21 47 -24 0 -4 -22 -15 -48 -25 -27 -9 -61 -26 -76 -38 -31 -25
-71 -100 -81 -152 -9 -48 -22 -47 -30 1 -3 20 -18 62 -33 92 -22 46 -36 59
-78 81 -29 14 -62 29 -75 33 -21 6 -15 11 47 40 88 41 106 66 151 206 3 8 13
-10 22 -40 10 -30 25 -69 34 -87z m305 68 c-4 -34 -43 -42 -53 -11 -9 27 10
49 36 43 15 -4 19 -12 17 -32z m-878 -58 c0 -31 49 -82 85 -89 46 -8 44 -20
-6 -35 -40 -12 -70 -43 -83 -87 -9 -28 -23 -21 -31 18 -8 35 -43 66 -85 75
-41 9 -38 20 9 34 42 12 77 48 87 88 7 25 24 22 24 -4z m1095 -10 c21 -50 51
-81 86 -88 40 -9 36 -20 -11 -39 -41 -16 -80 -64 -80 -98 0 -29 -17 -20 -24
12 -8 42 -45 76 -96 90 -47 13 -44 22 12 40 43 13 74 47 83 90 4 17 8 30 11
30 2 0 11 -17 19 -37z m545 -146 c23 -86 59 -130 134 -163 36 -15 66 -32 66
-35 0 -4 -22 -15 -49 -24 -72 -24 -116 -68 -141 -142 -11 -34 -20 -69 -20 -78
0 -30 -19 -14 -26 23 -13 72 -52 139 -101 171 -25 17 -61 33 -79 37 -19 3 -34
10 -34 14 0 4 30 20 66 35 77 33 110 71 139 163 11 34 23 58 26 54 3 -4 12
-28 19 -55z m-2147 -1 c19 -60 54 -96 112 -117 19 -7 35 -16 35 -19 0 -4 -18
-15 -39 -25 -53 -24 -78 -51 -107 -121 -21 -53 -24 -57 -31 -34 -3 13 -18 47
-32 75 -22 42 -36 55 -85 79 l-58 30 48 23 c65 31 92 59 112 115 9 26 19 48
23 48 3 0 13 -24 22 -54z m-475 -330 c16 -44 56 -83 93 -91 35 -8 38 -21 5
-29 -40 -10 -85 -55 -97 -96 -13 -44 -24 -44 -32 -2 -9 42 -44 80 -88 93 -21
7 -39 16 -39 20 0 5 18 13 39 19 44 12 76 46 86 90 9 39 18 38 33 -4z m3034
-54 c7 -8 33 -24 58 -35 l45 -20 -35 -15 c-50 -20 -79 -50 -86 -89 -9 -47 -19
-48 -32 -5 -14 47 -45 77 -97 95 l-39 14 56 25 c49 23 58 31 74 73 l18 48 13
-38 c7 -21 19 -45 25 -53z m-1445 -59 c70 -47 143 -107 143 -118 0 -3 -104 -5
-231 -5 -127 0 -229 4 -227 9 13 34 197 161 233 161 6 0 43 -21 82 -47z m-648
33 c-2 -2 -40 -25 -84 -52 -170 -102 -358 -257 -460 -378 -28 -32 -69 -81 -93
-108 -23 -27 -56 -74 -73 -104 l-31 -54 -125 0 -124 0 30 43 c127 176 455 437
688 549 123 59 296 125 272 104z m301 -10 c0 -2 -32 -36 -71 -75 l-71 -71 -91
0 c-54 0 -87 4 -81 9 13 13 167 90 233 116 54 22 81 29 81 21z m749 -90 c51
-26 91 -49 90 -50 -2 -2 -39 -6 -81 -10 l-78 -7 -75 76 c-41 41 -75 77 -75 80
0 9 124 -42 219 -89z m189 63 c263 -92 613 -341 802 -569 83 -100 88 -90 -42
-90 l-115 0 -52 79 c-131 201 -433 476 -638 580 -42 21 -60 40 -25 26 9 -4 41
-15 70 -26z m-1944 -95 c7 -19 -10 -44 -29 -44 -19 0 -36 25 -29 44 3 9 16 16
29 16 13 0 26 -7 29 -16z m-466 -26 c3 -25 0 -28 -27 -28 -26 0 -31 4 -31 23
0 30 9 39 35 35 13 -2 21 -12 23 -30z m3500 3 c4 -29 -15 -43 -40 -30 -21 11
-23 30 -6 47 19 19 43 10 46 -17z m-2268 -137 c0 -2 -11 -22 -24 -43 -35 -55
-119 -226 -147 -298 l-24 -63 -228 0 -228 0 15 28 c23 42 138 182 197 238 64
60 156 130 186 141 22 8 253 6 253 -3z m856 -5 c9 -7 32 -38 52 -69 42 -66
162 -300 162 -317 0 -10 -119 -13 -560 -13 -489 0 -560 2 -560 15 0 23 156
326 188 365 l29 35 336 -1 c267 -1 340 -4 353 -15z m473 -36 c109 -81 204
-176 272 -270 32 -44 59 -84 59 -87 0 -3 -101 -6 -225 -6 -173 0 -225 3 -225
13 0 16 -139 300 -173 354 l-26 42 22 4 c12 2 67 5 121 6 l99 1 76 -57z
m-2649 -76 c17 -15 44 -30 61 -34 38 -7 37 -19 -4 -32 -44 -15 -82 -56 -99
-109 l-15 -43 -17 45 c-20 52 -71 101 -115 111 -32 7 -33 25 -2 25 34 0 97 56
115 101 l18 44 14 -40 c7 -22 27 -53 44 -68z m3741 -4 c12 -12 34 -25 50 -28
40 -9 45 -23 13 -35 -51 -20 -83 -51 -95 -91 -14 -46 -24 -50 -34 -11 -11 46
-35 73 -86 97 l-50 23 56 26 c48 22 59 32 77 73 l21 48 14 -40 c7 -21 23 -49
34 -62z m-4010 -412 c12 -29 27 -43 57 -56 45 -20 46 -30 3 -40 -34 -7 -56
-30 -65 -67 -8 -34 -22 -36 -30 -5 -8 33 -43 67 -77 74 -40 8 -36 19 15 42 34
15 47 27 57 55 7 20 15 36 19 36 3 0 12 -18 21 -39z m4204 12 c9 -38 31 -60
71 -73 35 -12 46 -30 18 -30 -32 0 -74 -35 -90 -74 l-17 -41 -14 44 c-13 36
-23 46 -60 63 -25 11 -43 22 -40 24 2 3 24 15 48 27 33 16 46 30 53 55 11 39
22 41 31 5z m-3523 -100 c-36 -82 -76 -200 -93 -277 l-14 -56 -168 0 -168 0 6
23 c25 81 135 303 177 357 19 24 26 25 151 30 73 3 133 2 135 -2 2 -4 -10 -37
-26 -75z m660 81 c3 -3 -7 -45 -22 -93 -15 -48 -37 -140 -50 -204 l-23 -117
-269 0 c-213 0 -268 3 -268 13 0 19 72 231 109 319 l33 77 96 4 c190 6 389 7
394 1z m1396 -69 c21 -62 82 -312 82 -336 0 -5 -283 -9 -720 -9 l-719 0 5 33
c14 78 87 363 96 374 8 9 152 12 621 10 l611 -2 24 -70z m609 38 c36 -63 133
-339 133 -375 0 -5 -119 -8 -265 -8 -146 0 -265 4 -265 8 0 20 -51 246 -73
319 -13 46 -22 85 -19 87 2 3 108 5 236 6 l232 1 21 -38z m330 28 c50 -1 54
-3 78 -41 60 -96 165 -322 165 -357 0 -10 -36 -13 -149 -13 l-148 0 -27 101
c-15 56 -45 141 -65 188 -63 142 -64 131 21 127 40 -2 97 -4 125 -5z m-3812
-341 c0 -29 -34 -40 -54 -17 -18 23 -2 49 28 45 20 -2 26 -8 26 -28z m4463 3
c4 -29 -30 -49 -48 -28 -6 8 -9 23 -5 35 9 28 49 23 53 -7z m-4489 -208 c16
-37 29 -50 66 -66 51 -22 56 -34 21 -43 -27 -7 -86 -53 -86 -66 0 -5 -5 -21
-10 -36 -10 -27 -11 -27 -38 27 -23 46 -34 56 -70 69 -48 16 -50 29 -6 43 40
13 72 44 86 84 7 18 13 33 15 33 1 0 11 -20 22 -45z m4559 -48 c11 -10 32 -22
48 -26 32 -8 45 -31 18 -31 -33 0 -84 -44 -100 -87 -20 -51 -29 -54 -38 -12
-3 17 -19 44 -35 60 -16 16 -43 32 -60 35 -38 8 -40 21 -3 34 56 21 72 34 93
77 l22 45 17 -39 c9 -21 26 -46 38 -56z m-3864 -34 c-6 -49 -15 -145 -19 -215
l-7 -128 -175 0 -175 0 4 118 c3 64 13 161 23 215 l18 97 172 0 171 0 -12 -87z
m691 65 c-8 -38 -25 -258 -25 -335 l0 -73 -280 0 -280 0 0 59 c0 59 27 335 35
359 3 9 68 12 280 12 l275 0 -5 -22z m1619 -2 c7 -29 26 -281 26 -353 l0 -53
-770 0 -770 0 0 78 c0 78 16 288 25 330 l5 22 739 0 739 0 6 -24z m661 2 c14
-65 26 -166 32 -280 l6 -128 -270 0 -269 0 -12 199 c-6 109 -15 206 -18 215
-6 14 19 16 260 16 l266 0 5 -22z m449 14 c12 -20 38 -199 43 -304 l6 -118
-167 0 -166 0 0 80 c0 72 -11 210 -25 313 l-5 37 154 0 c85 0 157 -4 160 -8z
m-3442 -647 c8 -97 24 -236 34 -297 l7 -38 -160 0 -160 0 -11 38 c-16 55 -50
247 -57 325 l-6 67 173 -2 173 -3 7 -90z m688 25 c0 -63 8 -159 25 -312 l6
-48 -265 0 -265 0 -11 41 c-19 70 -38 190 -45 292 l-7 97 281 0 281 0 0 -70z
m1668 61 c6 -2 0 -81 -18 -236 -11 -93 -20 -173 -20 -178 0 -4 -330 -7 -734
-6 l-734 1 -12 83 c-14 96 -29 326 -22 339 3 5 1510 3 1540 -3z m659 1 c9 -5
9 -36 1 -123 l-11 -115 -34 -24 c-18 -12 -54 -50 -79 -84 -25 -34 -53 -64 -63
-68 -9 -4 -96 -7 -195 -7 l-179 -1 7 68 c12 120 26 296 26 330 l0 32 258 0
c141 0 262 -4 269 -8z m472 -19 c0 -10 -6 -47 -13 -83 l-12 -65 -154 -5 c-85
-3 -155 -6 -156 -5 0 0 2 39 4 88 l4 87 164 0 c150 0 164 -2 163 -17z m757
-352 c50 -36 89 -79 81 -87 -4 -4 -38 7 -75 24 -101 47 -111 44 -60 -19 37
-46 32 -60 -10 -30 -34 24 -91 28 -125 9 -16 -9 -124 -14 -372 -18 -404 -7
-407 -8 -468 -90 -32 -43 -42 -50 -72 -50 -34 0 -35 -1 -35 -40 0 -36 3 -40
33 -48 l32 -9 -33 -17 c-27 -14 -32 -21 -28 -44 2 -15 5 -32 5 -38 1 -7 10
-14 21 -17 28 -7 25 -24 -5 -31 -21 -5 -25 -12 -25 -42 0 -30 4 -37 30 -45 17
-6 30 -15 30 -21 0 -5 -3 -7 -7 -5 -5 3 -18 1 -30 -4 -30 -11 -31 -61 -3 -89
22 -22 19 -40 -6 -40 -10 0 -14 -12 -14 -40 0 -37 3 -41 30 -46 38 -8 38 -21
1 -29 -16 -3 -32 -13 -35 -21 -5 -14 -8 -14 -21 0 -13 12 -15 65 -15 348 0
326 1 335 23 378 12 25 42 60 66 78 36 27 52 33 84 30 46 -3 63 18 23 28 -45
11 -127 -21 -173 -68 -70 -72 -73 -88 -76 -460 l-2 -328 -22 0 -23 0 0 363 c0
359 0 362 24 412 24 53 70 99 121 121 23 10 146 13 561 14 l531 0 39 -29z
m-4132 -311 c23 -69 54 -151 69 -184 15 -32 27 -62 27 -67 0 -5 -54 -9 -120
-9 l-120 0 -37 58 c-20 31 -47 73 -59 92 -28 43 -90 175 -99 213 l-7 28 151
-3 152 -3 43 -125z m630 104 c4 -14 15 -67 26 -117 10 -51 28 -121 39 -157 12
-36 21 -71 21 -77 0 -10 -53 -13 -231 -13 l-230 0 -45 96 c-42 90 -104 258
-104 283 0 8 77 11 259 11 l259 0 6 -26z m1550 18 c5 -8 -52 -239 -80 -322
l-19 -55 -606 -3 -607 -2 -35 107 c-33 103 -48 162 -62 246 l-6 37 705 0 c388
0 707 -3 710 -8z m526 -162 c0 -107 -4 -178 -12 -195 l-11 -25 -230 0 -229 0
5 23 c2 12 16 60 30 107 20 66 57 234 57 256 0 2 88 4 195 4 l195 0 0 -170z
m-3202 104 c13 -26 20 -49 17 -53 -12 -12 -308 -13 -348 -1 -55 16 -75 81 -29
93 10 3 89 5 178 6 l160 1 22 -46z m5018 -120 c29 -8 56 -57 48 -88 -14 -56
-8 -55 -414 -57 l-374 -2 -18 23 c-25 30 -23 78 4 107 l21 23 356 0 c196 0
365 -3 377 -6z m-4893 -140 c21 -36 24 -46 13 -50 -8 -3 -96 -4 -196 -2 -178
3 -182 3 -201 27 -11 13 -19 34 -17 45 3 20 10 21 178 26 96 3 180 4 185 2 6
-1 23 -23 38 -48z m4010 -90 c47 -54 80 -64 219 -64 69 0 127 -3 130 -6 3 -3
-13 -23 -37 -45 l-42 -39 -112 0 c-126 0 -154 9 -195 63 -22 29 -26 44 -25
103 0 68 0 69 17 44 9 -13 29 -39 45 -56z m-3864 -153 c23 -27 41 -52 41 -55
0 -3 -85 -6 -189 -6 -179 0 -190 1 -222 23 -43 29 -52 73 -17 80 13 2 96 5
185 6 l161 1 41 -49z m443 -19 c37 -58 86 -121 173 -220 11 -13 69 -67 129
-120 60 -53 126 -112 145 -130 20 -18 60 -49 89 -69 29 -19 51 -38 48 -40 -2
-3 -65 24 -140 60 -203 98 -415 246 -542 379 -65 67 -164 186 -164 196 0 4 49
8 109 9 l109 2 44 -67z m570 20 c58 -129 131 -278 148 -302 11 -16 20 -31 20
-34 0 -3 -55 -6 -122 -6 -142 0 -131 -5 -262 115 -76 69 -236 251 -236 268 0
3 97 6 215 6 l215 1 22 -48z m1238 31 c0 -20 -117 -244 -162 -312 l-43 -63
-342 -1 c-273 0 -346 3 -359 14 -27 22 -84 118 -135 224 -72 152 -73 144 8
147 37 2 285 3 550 3 364 0 483 -3 483 -12z m560 -1 c0 -30 -195 -240 -307
-330 l-52 -42 -125 0 -125 0 36 53 c38 54 121 225 142 290 l13 37 209 0 c115
0 209 -4 209 -8z m125 -279 c-6 -18 -176 -142 -277 -203 -83 -49 -278 -140
-302 -140 -6 0 14 17 44 37 160 107 408 342 489 462 l36 54 7 -99 c4 -54 6
-104 3 -111z m-2743 17 l33 -29 -167 -1 -168 0 0 25 c0 24 1 24 98 28 53 1
114 4 134 5 29 2 45 -5 70 -28z m2873 -214 c30 -25 82 -55 115 -67 46 -17 58
-24 46 -31 -8 -5 -111 -7 -228 -6 l-213 3 60 33 c33 18 83 50 110 72 28 21 51
39 53 39 1 1 27 -19 57 -43z m-2622 -15 c29 -21 72 -49 97 -62 l45 -24 -228
-3 -227 -2 -54 26 c-61 29 -106 68 -106 89 0 13 33 15 210 15 l211 0 52 -39z
m890 -96 c38 -41 66 -75 64 -75 -32 0 -297 130 -297 146 0 2 37 4 83 4 l82 0
68 -75z m546 67 l53 -7 -28 -25 c-65 -57 -151 -117 -184 -129 -30 -10 -40 -8
-85 13 -47 24 -183 132 -184 149 -1 9 352 8 428 -1z m381 -13 c-39 -31 -280
-145 -280 -133 0 3 30 39 67 80 l68 74 85 0 85 0 -25 -21z m-1270 -269 c67
-22 169 -50 228 -62 l107 -22 -45 -9 c-25 -4 -193 -6 -375 -5 l-330 3 -64 35
c-69 38 -88 58 -78 84 6 14 32 16 222 16 l215 0 120 -40z m1934 34 c21 -8 20
-47 -2 -67 -10 -9 -40 -27 -67 -39 -48 -22 -63 -23 -335 -26 -195 -3 -297 0
-324 8 -48 14 -39 23 49 45 39 9 111 32 160 50 l90 33 207 1 c113 1 213 -2
222 -5z m-782 -282 c243 -9 266 -18 121 -53 -250 -61 -774 -67 -1088 -13 -94
16 -139 32 -136 49 2 17 749 28 1103 17z"/>
<path d="M4986 5981 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M4919 5943 l-24 -28 30 25 c17 14 32 26 34 28 2 1 0 2 -6 2 -5 0 -20
-12 -34 -27z"/>
<path d="M6876 5054 c-10 -25 2 -34 45 -34 32 0 39 4 39 19 0 32 -72 45 -84
15z"/>
<path d="M7007 5063 c-10 -3 -17 -12 -15 -21 5 -24 73 -29 82 -6 4 9 5 18 4
19 -8 6 -55 12 -71 8z"/>
<path d="M7117 5063 c-4 -3 -7 -15 -7 -25 0 -15 8 -18 45 -18 37 0 45 3 45 19
0 26 -63 45 -83 24z"/>
<path d="M7372 5059 c-35 -14 -21 -34 26 -37 37 -3 42 -1 42 17 0 12 -4 21 -9
21 -5 0 -15 2 -23 4 -7 3 -24 1 -36 -5z"/>
<path d="M7492 5059 c-35 -14 -21 -34 26 -37 37 -3 42 -1 42 17 0 12 -4 21 -9
21 -5 0 -15 2 -23 4 -7 3 -24 1 -36 -5z"/>
<path d="M7237 5054 c-15 -16 8 -34 44 -34 32 0 39 3 39 20 0 17 -7 20 -38 20
-21 0 -41 -3 -45 -6z"/>
<path d="M7600 5045 c0 -11 12 -15 45 -15 33 0 45 4 45 15 0 11 -12 15 -45 15
-33 0 -45 -4 -45 -15z"/>
<path d="M6760 4970 c-14 -11 -30 -20 -37 -20 -20 0 -43 -30 -43 -56 0 -37 31
-29 61 15 18 25 37 40 58 44 18 4 31 13 31 22 0 21 -40 19 -70 -5z"/>
<path d="M8221 5714 c-26 -34 -26 -54 -1 -82 26 -28 80 -29 103 -4 25 27 22
78 -5 96 -34 24 -73 20 -97 -10z"/>
<path d="M1825 5328 c-15 -14 -28 -38 -32 -60 -5 -32 -1 -41 25 -68 48 -48
126 -33 152 30 35 84 -75 158 -145 98z"/>
<path d="M1590 2693 c-8 -3 -23 -12 -32 -21 -17 -14 -18 -43 -18 -343 0 -325
0 -328 22 -349 29 -27 87 -27 122 1 26 20 26 22 26 145 l0 124 110 0 c104 0
112 1 135 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24 -31 25 -135 25
l-110 0 0 60 0 60 125 0 c120 0 127 1 150 25 39 38 32 101 -15 128 -23 14 -56
17 -197 16 -92 0 -175 -3 -183 -6z"/>
<path d="M2110 2680 l-25 -19 3 -328 c4 -316 4 -329 25 -350 25 -28 82 -30
115 -5 22 17 22 17 25 350 l2 333 -25 19 c-32 25 -88 25 -120 0z"/>
<path d="M2392 2680 c-22 -21 -22 -24 -22 -349 l0 -328 23 -21 c30 -28 85 -29
121 -1 l26 20 0 197 0 197 107 -110 c59 -60 154 -158 212 -217 95 -99 108
-108 141 -108 71 0 70 -5 70 373 0 336 0 336 -22 351 -32 23 -90 20 -122 -5
l-26 -20 0 -185 c0 -101 -3 -184 -6 -184 -6 0 -81 75 -276 278 -120 124 -129
132 -166 132 -25 0 -46 -7 -60 -20z"/>
<path d="M3233 2693 c-51 -10 -53 -23 -53 -368 0 -309 1 -322 20 -343 20 -21
28 -22 214 -22 180 0 195 1 220 21 32 26 36 84 7 120 -18 23 -26 24 -155 27
l-136 4 0 57 0 58 61 7 c34 3 90 6 124 6 74 0 100 20 98 77 -2 66 -26 77 -163
82 l-120 3 0 54 0 54 130 0 c126 0 132 1 155 25 31 30 33 83 6 116 -19 24 -23
24 -203 25 -100 1 -193 0 -205 -3z"/>
<path d="M3870 2680 c-89 -42 -130 -104 -130 -197 0 -106 50 -162 197 -223 91
-37 113 -54 113 -86 0 -53 -82 -69 -180 -34 -77 27 -104 25 -130 -7 -31 -39
-23 -71 27 -109 59 -45 141 -67 228 -62 77 5 117 22 164 69 47 46 64 87 64
150 0 103 -59 166 -208 224 -85 33 -105 49 -105 87 0 23 7 32 35 45 34 16 39
16 111 -5 68 -20 79 -21 99 -8 31 20 50 71 38 99 -30 65 -229 100 -323 57z"/>
<path d="M4310 2693 c-36 -14 -50 -37 -50 -80 0 -63 18 -77 104 -83 l71 -5 3
-260 c2 -250 3 -260 24 -282 28 -30 85 -31 122 -2 l26 20 0 259 c0 292 -7 270
89 270 67 0 88 16 97 72 5 27 1 41 -19 64 l-25 29 -214 2 c-117 1 -220 -1
-228 -4z"/>
<path d="M5039 2681 c-36 -29 -28 -72 51 -301 40 -113 86 -245 103 -294 35
-101 54 -126 96 -126 49 0 68 27 124 180 28 80 56 144 61 143 5 -1 30 -57 56
-125 55 -146 74 -183 95 -192 55 -21 95 14 130 114 110 309 185 543 180 561
-11 33 -47 59 -81 59 -36 0 -80 -31 -89 -63 -4 -12 -29 -93 -57 -181 l-51
-158 -63 188 c-37 110 -70 194 -80 201 -25 18 -69 16 -85 -3 -8 -9 -41 -100
-74 -202 l-60 -185 -59 179 c-32 98 -68 189 -79 202 -26 28 -85 30 -118 3z"/>
<path d="M6155 2675 c-14 -13 -25 -29 -25 -35 0 -6 -23 -63 -51 -128 -194
-448 -211 -499 -171 -535 21 -19 73 -22 103 -7 10 6 35 42 54 80 l35 70 123 0
123 0 34 -71 c19 -39 42 -75 52 -80 26 -14 81 -11 106 7 42 29 36 50 -122 426
-53 126 -96 232 -96 236 0 5 -11 21 -25 36 -34 36 -105 37 -140 1z m102 -320
l32 -75 -65 0 c-44 0 -64 4 -64 13 0 15 53 136 61 137 3 0 19 -34 36 -75z"/>
<path d="M6655 2684 c-19 -14 -20 -28 -23 -349 l-3 -334 23 -20 c20 -20 33
-21 200 -21 165 0 179 1 198 20 26 26 27 89 1 121 -18 22 -28 24 -135 27
l-115 4 -3 265 c-3 265 -3 265 -27 284 -28 23 -88 25 -116 3z"/>
<path d="M7166 2679 l-26 -20 0 -328 0 -328 23 -21 c22 -21 32 -22 204 -22
167 0 182 1 207 21 31 24 35 66 9 109 -20 34 -45 40 -174 40 l-99 0 0 264 0
265 -26 20 c-15 12 -41 21 -59 21 -18 0 -44 -9 -59 -21z"/>
<path d="M7710 2693 c-8 -3 -23 -12 -32 -21 -16 -14 -18 -43 -18 -342 0 -314
1 -327 20 -348 20 -21 28 -22 209 -22 175 0 190 1 215 21 36 28 37 89 1 124
-23 24 -30 25 -150 25 l-125 0 0 59 0 58 68 6 c37 4 90 7 118 7 63 0 94 27 94
82 0 62 -23 73 -159 73 l-116 0 -3 58 -3 57 121 0 c67 0 130 5 141 11 27 14
42 57 34 97 -10 53 -45 62 -237 61 -90 0 -170 -3 -178 -6z"/>
<path d="M8219 2689 c-49 -18 -64 -88 -29 -132 18 -23 29 -26 93 -29 l72 -3 5
-268 c5 -251 6 -270 24 -283 29 -21 89 -17 119 7 l27 20 2 262 3 262 72 3 c64
3 75 6 93 29 38 48 21 112 -34 133 -34 13 -411 12 -447 -1z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
